<template>
  <v-tooltip top>
    <template v-slot:activator="{on}">
      <v-btn @click="onClickHandler" :icon="!!icon" v-bind="$attrs" v-on="on">
        <v-icon v-if="icon">{{ icon }}</v-icon>
        <slot v-else />
      </v-btn>
    </template>
    <template v-slot:default>
      {{ tooltip }}
    </template>
  </v-tooltip>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    tooltip: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
  },
  methods: {
    onClickHandler() {
      this.$emit('click')
    },
  },
}
</script>

<style scoped></style>
