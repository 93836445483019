<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{on}">
      <v-text-field
        prepend-inner-icon="mdi-calendar-range"
        clearable
        readonly
        outlined
        dense
        hide-details
        autocomplete="off"
        v-bind="[$attrs]"
        v-on="on"
        :class="inputClasses"
        :value="dates"
        @input="onInput"
      >
      </v-text-field>
    </template>
    <v-date-picker
      show-adjacent-months
      locale="pl-PL"
      first-day-of-week="1"
      :range="true"
      :value="value"
      no-title
      @input="onInput"
    />
  </v-menu>
</template>

<script>
import dateFilter from '@/filters/date.filter'

export default {
  name: 'DateRangePicker',
  props: {
    value: {
      type: [String, Array],
      required: false,
    },
    inputClasses: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      menu: false,
    }
  },
  methods: {
    onInput(e) {
      this.$emit('input', e)
    },
  },
  computed: {
    dates() {
      const {value} = this
      if (value) {
        return value.map(itm => dateFilter(itm)).join(' - ')
      }
      return 'Wszystkie'
    },
  },
}
</script>

<style scoped></style>
