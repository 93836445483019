<template>
  <v-card>
    <v-card-title>
      <DateRangePicker input-classes="mr-2 shrink" v-model="dateRange" />
      <DefaultModal
        :pending="isAddingInProgress"
        ref="modal"
        @formSubmitted="formSubmitHandler"
        title="Dodawanie do licytacji"
        activator-name="Dodaj"
      >
        <AllCategoriesAutocomplete v-model="selectedCategory" />
      </DefaultModal>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Wyszukaj"
        class="shrink"
        single-line
        hide-details
        @keyup.enter="fetchItems"
        @blur="fetchItems"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="$data.$_paginationMixin_options"
      item-key="id"
      :loading="$data.$_paginationMixin_fetchingItems"
      :search="search"
      :items-per-page="$data.$_paginationMixin_itemsPerPage"
      :page="1"
      :server-items-length="$data.$_paginationMixin_totalCount"
      :footer-props="{
        'items-per-page-options': $data.$_paginationMixin_rowsPerPageOptions,
      }"
    >
      <template v-slot:item.category="{item}">
        {{ item.category.name }}
      </template>
      <template v-slot:item.dateOfAnalize="{item}">
        {{ item.dateOfAnalize | date }}
      </template>
      <template v-slot:item.dateOfBid="{item}">
        {{ setStatus(item.dateOfBid) }}
      </template>
      <template v-slot:item.actions="{item}">
        <ButtonTooltip @click="unbidCategory(item)" icon="mdi-refresh" tooltip="Zeruj stawkę" />
        <ButtonTooltip @click="deleteCategory(item)" icon="mdi-close" tooltip="Usuń z listy" />
      </template>
    </v-data-table>
    <Confirm ref="modalConfirm" />
  </v-card>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import paginationMixin from '@/mixins/pagination.mixin.js'
import DefaultModal from '@/components/Modals/DefaultModal'
import AllCategoriesAutocomplete from '@/components/UI/Autocompletes/AllCategoriesAutocomplete'
import RepositoryFactory from '@/repositories/RepositoryFactory'
import DateRangePicker from '@/components/UI/DateRangePicker'
import ButtonTooltip from '@/components/UI/ButtonTooltip'
import dateFilter from '@/filters/date.filter'
import Confirm from '@/components/UI/Confirm'

const CategoryRepository = RepositoryFactory.get('categories')

export default {
  mixins: [paginationMixin],
  components: {
    DateRangePicker,
    AllCategoriesAutocomplete,
    DefaultModal,
    ButtonTooltip,
    Confirm,
  },
  data() {
    return {
      selectedCategory: null,
      search: '',
      isAddingInProgress: false,
      dateRange: null,
      headers: [
        {text: 'Nazwa kategorii', value: 'category', width: '50%'},
        {text: 'Status', value: 'dateOfBid', width: '10%'},
        {text: 'Ost. licytacja', value: 'dateOfAnalize', width: '10%'},
        {text: '', value: 'actions', sortable: false, width: '10%'},
      ],
    }
  },
  watch: {
    searchOptions: {
      handler() {
        this.fetchItems()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['fetchBiddingCategories', 'showNotification']),
    async unbidCategory(item) {
      const {category} = item
      const res = await this.$refs.modalConfirm.open({
        title: `Zerowanie stawek`,
        message: `Czy na pewno chcesz wyzerować stawki dla kategorii "${category.name}"?`,
      })
      if (res) {
        try {
          await CategoryRepository.unbidCategory(category.id)
          this.showNotification({
            message: 'NOTY_BIDDING_CATEGORIES_UNBIDDED',
            type: 'success',
          })
          await this.fetchItems()
        } catch (e) {
          this.showNotification({
            message: e.response.data,
            type: 'error',
          })
        }
      }
    },
    async deleteCategory(item) {
      const {category} = item
      const res = await this.$refs.modalConfirm.open({
        title: `Usuwanie z listy licytacji`,
        message: `Czy na pewno chcesz usunąć kategorię "${category.name}" z listy licytacji?`,
      })
      if (res) {
        try {
          await CategoryRepository.deleteFromBidding(category.id)
          this.showNotification({
            message: 'NOTY_BIDDING_CATEGORIES_REMOVED',
            type: 'success',
          })
          await this.fetchItems()
        } catch (e) {
          this.showNotification({
            message: e.response.data,
            type: 'error',
          })
        }
      }
    },
    async fetchItems() {
      const {search, dateRange} = this
      this.$data.$_paginationMixin_fetchingItems = true
      const dRange = dateRange ? [...dateRange] : null
      const options = {
        dateBiddingFrom: dRange ? dRange.shift() : null,
        dateBiddingTo: dRange ? dRange.pop() : null,
      }
      const query = this.$_paginationMixin_createQuery(
        {
          ...this.$data.$_paginationMixin_options,
          search,
        },
        options
      )
      this.$data.$_paginationMixin_totalCount = await this.fetchBiddingCategories({
        ...query,
        ...options,
      })
      this.$data.$_paginationMixin_fetchingItems = false
    },
    setStatus(date) {
      return dateFilter(date) === '-' ? 'Oczekuje' : 'Podbita'
    },
    async formSubmitHandler() {
      try {
        this.isAddingInProgress = true
        await CategoryRepository.bidCategory(this.selectedCategory)
        await this.fetchItems()
        this.showNotification({
          message: 'NOTY_BIDDING_CATEGORIES_BIDDED',
          type: 'success',
        })
        this.$refs.modal.close()
        this.selectedCategory = null
      } catch (e) {
        this.showNotification({
          message: e.response.data,
          type: 'error',
        })
      }
      this.isAddingInProgress = false
    },
  },
  computed: {
    ...mapState({
      items: state => state.categories.biddingCategories,
    }),
    searchOptions() {
      return {
        ...this.$data.$_paginationMixin_options,
        dateRange: this.dateRange,
      }
    },
  },
}
</script>

<style></style>
