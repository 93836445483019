var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('DateRangePicker',{attrs:{"input-classes":"mr-2 shrink"},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('DefaultModal',{ref:"modal",attrs:{"pending":_vm.isAddingInProgress,"title":"Dodawanie do licytacji","activator-name":"Dodaj"},on:{"formSubmitted":_vm.formSubmitHandler}},[_c('AllCategoriesAutocomplete',{model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}})],1),_c('v-spacer'),_c('v-text-field',{staticClass:"shrink",attrs:{"append-icon":"mdi-magnify","label":"Wyszukaj","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchItems($event)},"blur":_vm.fetchItems},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.$data.$_paginationMixin_options,"item-key":"id","loading":_vm.$data.$_paginationMixin_fetchingItems,"search":_vm.search,"items-per-page":_vm.$data.$_paginationMixin_itemsPerPage,"page":1,"server-items-length":_vm.$data.$_paginationMixin_totalCount,"footer-props":{
      'items-per-page-options': _vm.$data.$_paginationMixin_rowsPerPageOptions,
    }},on:{"update:options":function($event){return _vm.$set(_vm.$data, "$_paginationMixin_options", $event)}},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.category.name)+" ")]}},{key:"item.dateOfAnalize",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.dateOfAnalize))+" ")]}},{key:"item.dateOfBid",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setStatus(item.dateOfBid))+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('ButtonTooltip',{attrs:{"icon":"mdi-refresh","tooltip":"Zeruj stawkę"},on:{"click":function($event){return _vm.unbidCategory(item)}}}),_c('ButtonTooltip',{attrs:{"icon":"mdi-close","tooltip":"Usuń z listy"},on:{"click":function($event){return _vm.deleteCategory(item)}}})]}}])}),_c('Confirm',{ref:"modalConfirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }